<template>
  <div class="section-content">
    <layout-loader v-show="loading"/>
    <div class="container">
<!--      <div class="menu" @click="opened = !opened">
        <img v-if="!opened" src="@/assets/icons/filters.svg" alt="" />
        <img v-else src="@/assets/icons/close.svg" alt="" />
      </div>
      <div class="left" :class="[{ opened }]">
        <div class="links">
          <slot name="links"></slot>
        </div>
      </div>
      <div class="right">-->
      <div>
        <div class="great-title">
          <slot name="title"></slot>
        </div>
        <div class="content rich-text">
          <slot name="content"></slot>

          <form v-if="paystripe" method="post" onsubmit="false" action="/" name="net2pay" id="epayment-net2pay-page-form" accept-charset="UTF-8" style="width: 100%; max-width: 450px;">
            <div>
              <br /><br />
              You need to enter the order id.<br /><br />
            </div>
            <div class="bd-example">
              <div class="form-group row">
                <label for="example-text-input" class="col-xs-4 col-form-label">Order ID</label>
                <div class="col-xs-8">
                  <input v-model="orderid" required class="form-control" type="text" id="orderid">
                </div>
              </div>
              <br>
              <div class="form-group row">
                  <label for="example-text-input" class="col-xs-4 col-form-label">Amount , EUR</label>
                  <div class="col-xs-8">
                      <input v-model="priceorder" required class="form-control" type="text" id="priceorder">
                  </div>
              </div>
              <input style="cursor:pointer;margin-top: 20px;" @click="net2paySubmit()" type="button" id="edit-submit" name="op" value="Create a payment link">
              <a v-if="linkpay" id="linkpay" target="_blank" :href="linkpay">Go to payment</a>
              <div style="word-wrap: break-word;" v-if="linkpay"> {{ linkpay }} </div>
            </div>

          </form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    paystripe:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      opened: false,
      orderid: '',
      priceorder: '',
      linkpay: '',
      loading: false
    }
  },
  methods: {
    net2paySubmit(){
      if(this.orderid != '' && this.priceorder != '') {
        this.loading = true;
        this.$axios({
          url: '/ajax/payOnline.php', method: 'post', data: {orderId: this.orderid, priceorder: this.priceorder}
        }).then(response => {
          if (response.status == 200 && response.data && response.data.url) {
            this.linkpay = response.data.url;
          }
          this.loading = false;
        }).catch(e => {
          console.log('Error: ' + e)
          this.loading = false;
        })
      }
    }
  },
    mounted(){
        document.querySelector('.doH1').remove();
    }
}
</script>

<style lang="scss" scoped>
.section-content {
  padding-bottom: 5em;
  position: relative;
}
.container {
 /* display: grid;
  grid-template-columns: 18em 47.5em;
  grid-gap: 4em;*/
}
.links {
  border-left: solid 1px rgba(0, 0, 0, 0.06);
  & > * {
    display: block;
    font-size: 0.875em;
    text-transform: uppercase;
    padding: 0.6em 2em;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      opacity: 1 !important;
    }
    &.active {
      border-left: solid 1px;
    }
    &.inactive {
      opacity: 0.3;
    }
  }
}
.content {
  margin-top: 2em;
}
.menu {
  display: none;
}
@media screen and (max-width: 767px) {
  .container {
    display: block;
  }
  .left {
    position: absolute;
    left: 0;
    top: 2em;
    width: 100%;
    background: #fff;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    padding: 2em;
    opacity: 0;
    visibility: hidden;
    transform: translate(0, 2em);
    transition: 0.5s;
    &.opened {
      transform: translate(0, 0);
      opacity: 1;
      visibility: visible;
    }
  }
  .links {
    border-left: none;
  }
  .menu {
    display: flex;
    margin-bottom: 1em;
  }
}
</style>
<style lang="scss">
.sitemap-section {
    margin: 8px 20px;
    border-left: #666666 solid 4px;
    padding: 5px;
    h1 {
        font-size: 24px;
    }
    div {
        padding:4px 12px;
        border-left:#666 dashed 2px;
        border-bottom:#ccc solid 1px;
    }
    p {
        font-weight:bold;
    }
    .PageBreak{
        margin-top:16px;
        span {
            font-size:14px;
            font-weight:bold;
        }
        a {
            font-size:14px;
            &:hover{
                font-size:14px;
            }
        }
    }
}
#payOnline, #error{
  opacity: 0;
  visibility: hidden;
}
#payOnline input[type=submit] {
  background: #f7990c;
  color: #fff;
  border: 0;
  height: 30px;
  margin: 0 10px;
}
.buutonSendPayOnline {
  background: #f7990c;
  color: #fff;
  border: 0;
  height: 33px;
  margin: 0 10px;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 4px;
}
#linkpay {
  resize: none;
  width: 100%;
  font-size: 0.9375em;
  font-family: inherit;
  color: black;
  background-color: rgb(240, 240, 240);
  height: 3.33em;
  padding: 0 0.5em;
  border: solid 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
