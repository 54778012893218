import Vue from '@/index.js'

import page from '@/mixins/page'

import sectionContent from '@/components/sections/section-content'
import Loader from '@/components/layout/layout-loader'

export default new Vue({
  el: '#app',
  components: { sectionContent, Loader },
  mixins: [page]
})
